import { Address, ConfirmedTypes, SelectOption } from '@/@types';
import { getAppointmentAddress } from '@/utils';
import { DateTime } from 'luxon';

type Props = {
  addressId?: string | null | undefined;
  additionalProviders?: SelectOption[] | null;
  appointmentType?: SelectOption | null;
  athenaDepartmentId?: number;
  atriaAppointment?: boolean;
  confirmed?: ConfirmedTypes | null;
  date: string;
  description?: string | null;
  end: string;
  id?: number;
  liaison?: SelectOption | null;
  parentId?: number | null;
  patient?: {
    id: number;
    patientName: string;
    firstName: string;
    lastName: string;
    firstNameUsed?: string | null;
    primaryProviderId?: number | null;
  } | null;
  primaryProvider?: SelectOption | null;
  resources?: SelectOption[] | null;
  rooms?: SelectOption[];
  start: string;
  syncToAthena?: boolean | null;
  syncToGoogle?: boolean | null;
  title: string;
  address?: Address | null;
  googleCalendarTitle?: string | null;
};

export async function mountAppointmentObject({
  address,
  addressId,
  additionalProviders,
  appointmentType,
  athenaDepartmentId,
  atriaAppointment,
  confirmed,
  date,
  description,
  end,
  id,
  liaison,
  parentId,
  patient,
  primaryProvider,
  resources,
  rooms,
  start,
  syncToAthena,
  syncToGoogle,
  title,
  googleCalendarTitle,
}: Props) {
  const formattedStart = DateTime.fromFormat(`${date} ${start}`, 'M/d/yy T');
  const formattedEnd = DateTime.fromFormat(`${date} ${end}`, 'M/d/yy T');
  const { minutes: duration } = formattedEnd.diff(formattedStart, 'minutes');

  const appointmentProviders = [
    ...(additionalProviders?.map((p) => ({
      id: p.id,
      name: p.label,
      type: 'ADDITIONAL',
    })) || []),
    ...(resources?.map((p) => ({
      id: p.id,
      name: p.label,
      type: 'EQUIPMENT',
    })) || []),
  ];

  if (liaison) {
    appointmentProviders.push({
      id: liaison.id,
      name: liaison.label,
      type: 'LIAISON',
    });
  }

  const addressObject = addressId ? await getAppointmentAddress(addressId) : address ?? null;

  const patientObj = patient
    ? {
        id: patient.id,
        firstName: patient.firstName,
        lastName: patient.lastName,
        firstNameUsed: patient.firstNameUsed,
        primaryProviderId: patient.primaryProviderId,
      }
    : undefined;

  return {
    ...(addressObject || { address: null }),
    athenaDepartmentId: athenaDepartmentId,
    atriaAppointment: atriaAppointment ? atriaAppointment : false,
    confirmed: confirmed as ConfirmedTypes,
    date: formattedStart.toUTC().toISO()!,
    description,
    duration,
    id,
    ...(patient?.id && {
      patient: patientObj,
    }),
    ...(parentId && { parentId }),
    providerId: primaryProvider?.id,
    providerName: primaryProvider?.label,
    providers: appointmentProviders,
    rooms: rooms?.map(({ id: roomId }) => ({ id: roomId })) || [],
    syncToAthena: syncToAthena ?? false,
    syncToGoogle: syncToGoogle ?? false,
    title,
    typeId: appointmentType?.id ?? null,
    type: appointmentType?.label ?? null,
    googleCalendarTitle: googleCalendarTitle,
  };
}
