import { ReactNode, useState } from 'react';
import { Popover } from 'react-tiny-popover';

interface CalendarPopoverProps {
  content: ReactNode;
  children: ReactNode;
  position?: 'top' | 'right' | 'bottom' | 'left';
}

export const CalendarPopover = ({
  content,
  children,
  position = 'bottom',
}: CalendarPopoverProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={[position]}
      align='start'
      onClickOutside={() => setIsPopoverOpen(false)}
      content={
        <div className='bg-product-grey-100 text-black mt-4 p-0 pl-1 pr-1 text-xs text-left border border-product-grey-400'>
          {content}
        </div>
      }
    >
      <div onMouseEnter={() => setIsPopoverOpen(true)} onMouseLeave={() => setIsPopoverOpen(false)}>
        {children}
      </div>
    </Popover>
  );
};

export default CalendarPopover;
