import { DateTime, Interval } from 'luxon';
import { Dispatch, SetStateAction, useEffect, MutableRefObject, useState } from 'react';
import { Toast } from 'primereact/toast';

import { Appointment, Atria } from '@/@types';
import { SyncHelper } from '@/helpers/syncHelper';
import { useWebSocket } from '@/contexts/webSocket/webSocketContext';

enum SCHEDULER_MESSAGES {
  CREATE_APPOINTMENT = 'CREATE_APPOINTMENT',
  CREATE_MULTIPLE_APPOINTMENTs = 'CREATE_MULTIPLE_APPOINTMENTs',
  DELETE_APPOINTMENT = 'DELETE_APPOINTMENT',
  DELETE_MULTIPLE_APPOINTMENTS = 'DELETE_MULTIPLE_APPOINTMENTS',
  UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT',
  UPDATE_MULTIPLE_APPOINTMENTS = 'UPDATE_MULTIPLE_APPOINTMENTS',
  RESTORE_APPOINTMENTS = 'RESTORE_APPOINTMENTS',
  APPOINTMENTS_WITH_CONFLICT = 'APPOINTMENTS_WITH_CONFLICT',
  APPOINTMENT_GOOGLE_SYNC = 'APPOINTMENT_GOOGLE_SYNC',
  APPOINTMENT_ATHENA_SYNC = 'APPOINTMENT_ATHENA_SYNC',
}

export type CreateAppointment = Atria.Appointment & {
  externalClient: boolean;
};

export type UpdateAppointment = Atria.Appointment;

export type DeleteMultipleAppointments = {
  appointmentIds: number[];
};

export type GoogleAppointmentSync = {
  success: boolean;
  appointmentId: number;
  googleCalendarEventId?: string | null;
};

export type AthenaAppointmentSync = {
  success: boolean;
  appointmentId: number;
  athenaAppointmentId?: number | null;
};

export type Conflict = Atria.Conflict;

export type SchedulerMessage = {
  client: string | undefined;
  type: string;
  payload:
    | Appointment
    | Pick<Appointment, 'appointmentId'>
    | UpdateAppointment
    | DeleteMultipleAppointments
    | UpdateAppointment[]
    | number[]
    | Conflict[];
};

type MessagePayload = {
  type: string;
  payload: any;
};

export const STANDARD_TIMEZONE = 4;

export const useSchedulerSocket = (
  setAppointments: Dispatch<SetStateAction<Atria.Appointment[]>>,
  date: Date,
  toast?: MutableRefObject<Toast> | undefined
) => {
  const { subscribe, unsubscribe } = useWebSocket();
  const [lastJsonMessage, setLastJsonMessage] = useState<MessagePayload>({
    type: '',
    payload: '',
  });

  useEffect(() => {
    const handleMessage = (data: any) => {
      setLastJsonMessage(data);
    };

    Object.values(SCHEDULER_MESSAGES).forEach((type) => subscribe(type, handleMessage));

    return () => {
      Object.values(SCHEDULER_MESSAGES).forEach((type) => unsubscribe(type, handleMessage));
    };
  }, [subscribe, unsubscribe, setLastJsonMessage]);

  useEffect(() => {
    switch (lastJsonMessage?.type) {
      case SCHEDULER_MESSAGES.CREATE_APPOINTMENT: {
        const appointment = lastJsonMessage?.payload as CreateAppointment;
        const dateStartOfWeek = DateTime.fromJSDate(date).startOf('week');
        const dateEndOfWeek = DateTime.fromJSDate(date).endOf('week');
        const startAndEndOfWeekInterval = Interval.fromDateTimes(dateStartOfWeek, dateEndOfWeek);

        if (startAndEndOfWeekInterval.contains(DateTime.fromISO(appointment.date))) {
          const newAppointment = {
            ...appointment,
            externalClient: true,
          };

          setAppointments((prev) => {
            if (prev.findIndex((x) => x.appointmentId === newAppointment.appointmentId) === -1) {
              return [...prev, newAppointment];
            }
            return prev;
          });
        }
        break;
      }
      case SCHEDULER_MESSAGES.CREATE_MULTIPLE_APPOINTMENTs: {
        const appointments = lastJsonMessage?.payload as CreateAppointment[];
        for (const appointment of appointments) {
          const dateStartOfWeek = DateTime.fromJSDate(date).startOf('week');
          const dateEndOfWeek = DateTime.fromJSDate(date).endOf('week');
          const startAndEndOfWeekInterval = Interval.fromDateTimes(dateStartOfWeek, dateEndOfWeek);

          if (startAndEndOfWeekInterval.contains(DateTime.fromISO(appointment.date))) {
            const newAppointment = {
              ...appointment,
              externalClient: true,
            };

            setAppointments((prev) => {
              if (prev.findIndex((x) => x.appointmentId === newAppointment.appointmentId) === -1) {
                return [...prev, newAppointment];
              }
              return prev;
            });
          }
        }

        break;
      }
      case SCHEDULER_MESSAGES.UPDATE_APPOINTMENT: {
        const updated = lastJsonMessage?.payload as UpdateAppointment;
        setAppointments((prev) => {
          const listWithoutUpdatedEvent = prev.filter(
            (app) => app.appointmentId !== updated.appointmentId
          );
          return [...listWithoutUpdatedEvent, updated];
        });
        break;
      }
      case SCHEDULER_MESSAGES.UPDATE_MULTIPLE_APPOINTMENTS: {
        const updatedAppointments = lastJsonMessage?.payload as UpdateAppointment[];
        setAppointments((prev) => {
          const listWithoutUpdatedEvent = prev.filter(
            (app) => !updatedAppointments.map((x) => x.appointmentId).includes(app.appointmentId)
          );
          return [...listWithoutUpdatedEvent, ...updatedAppointments];
        });
        break;
      }
      case SCHEDULER_MESSAGES.DELETE_APPOINTMENT: {
        const deletedAppointment = lastJsonMessage?.payload as { appointmentId: number };
        setAppointments((prev: Atria.Appointment[]) =>
          prev.filter((a) => a.appointmentId !== deletedAppointment?.appointmentId)
        );
        break;
      }
      case SCHEDULER_MESSAGES.DELETE_MULTIPLE_APPOINTMENTS: {
        const deletedAppointments = lastJsonMessage?.payload as DeleteMultipleAppointments;
        setAppointments((prev: Atria.Appointment[]) =>
          prev.filter((a) => !deletedAppointments?.appointmentIds?.includes(a.appointmentId))
        );

        break;
      }
      case SCHEDULER_MESSAGES.RESTORE_APPOINTMENTS: {
        const restoredAppointments = lastJsonMessage?.payload as Atria.Appointment[];
        setAppointments((prev) => [...prev, ...restoredAppointments]);
        break;
      }
      case SCHEDULER_MESSAGES.APPOINTMENTS_WITH_CONFLICT: {
        const conflicts = lastJsonMessage?.payload as Conflict[];
        const conflictIds = conflicts.map((conflict) => conflict.appointmentId);
        setAppointments((prev) =>
          prev.map((appt) => ({
            ...appt,
            hasConflict: conflictIds.includes(appt.id),
            conflicts: conflicts.filter((conflict) => conflict.appointmentId === appt.id),
          }))
        );
        break;
      }
      case SCHEDULER_MESSAGES.APPOINTMENT_GOOGLE_SYNC: {
        const googleSyncAppointment = lastJsonMessage?.payload as GoogleAppointmentSync;

        if (!googleSyncAppointment.success) {
          SyncHelper.showErrorNotification(toast, 'Google');
          return;
        }

        setAppointments((prev) =>
          prev.map((appt) => ({
            ...appt,
            googleCalendarEventId:
              appt.appointmentId === googleSyncAppointment.appointmentId
                ? googleSyncAppointment.googleCalendarEventId
                : appt.googleCalendarEventId,
          }))
        );
        break;
      }
      case SCHEDULER_MESSAGES.APPOINTMENT_ATHENA_SYNC: {
        const athenaSyncAppointment = lastJsonMessage?.payload as AthenaAppointmentSync;

        if (!athenaSyncAppointment.success) {
          SyncHelper.showErrorNotification(toast, 'Athena');
          return;
        }

        setAppointments((prev) =>
          prev.map((appt) => ({
            ...appt,
            athenaAppointmentId:
              appt.appointmentId === athenaSyncAppointment.appointmentId
                ? athenaSyncAppointment.athenaAppointmentId
                : appt.athenaAppointmentId,
          }))
        );
        break;
      }
      default:
        break;
    }
  }, [date, lastJsonMessage, setAppointments, toast]);
};
