import { Outlet } from 'react-router-dom';
import { ProvidersContext, RoomsContext } from '@/hooks';
import { AppointmentActionsContext } from '@/features/AppointmentActions';
import { AppointmentTypeContext } from './appointmentTypeContext';
import { LocationContext } from './locationContext';
import { WebSocketProvider } from './webSocket/webSocketProvider';

export function AppointmentsContexts() {
  return (
    <WebSocketProvider>
      <RoomsContext>
        <ProvidersContext>
          <AppointmentTypeContext>
            <LocationContext>
              <AppointmentActionsContext>
                <Outlet />
              </AppointmentActionsContext>
            </LocationContext>
          </AppointmentTypeContext>
        </ProvidersContext>
      </RoomsContext>
    </WebSocketProvider>
  );
}
