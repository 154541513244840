import { Atria, ConfirmedTypes } from '@/@types';
import { useAppointmentActions } from '@/features/AppointmentActions';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';

import GCalIcon from '@/assets/gcal-icon.png';
import AthenaIcon from '@/assets/athena-icon.png';
import { getAppointmentTypeColor } from '@/features/Calendar/utils';
import { mountAppointmentObject } from '@/utils';

type AppointmentItemProps = {
  item: Atria.Appointment;
};

const getContrastYIQ = (hexcolor: string) => {
  const r = parseInt(hexcolor.substring(1, 3), 16);
  const g = parseInt(hexcolor.substring(3, 5), 16);
  const b = parseInt(hexcolor.substring(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 128 ? 'text-product-forest-100' : 'text-white';
};

export const AppointmentItem = ({ item }: AppointmentItemProps) => {
  const { onAppointmentDetailsClick } = useAppointmentActions();

  const appointmentParsed = useMemo(() => mountAppointmentObject(item), [item]);

  const formatDate = useMemo(() => {
    const startFormatted = DateTime.fromISO(item.date).toFormat('dd/LL/yyyy h:mm');
    const endFormatted = DateTime.fromISO(item.end).toFormat('h:mma');

    return `${startFormatted}-${endFormatted}`;
  }, [item]);

  const handleAppointmentClick = useCallback(() => {
    onAppointmentDetailsClick(appointmentParsed);
  }, [appointmentParsed]);

  const statusIcon = useMemo(() => {
    switch (item.confirmed) {
      case ConfirmedTypes.FULLY_CONFIRMED:
        return 'check';
      case ConfirmedTypes.HOLD_CONFIRMED:
        return 'circle';
      case ConfirmedTypes.UNCONFIRMED:
        return 'circle-fill';
      default:
        return '';
    }
  }, [item.confirmed]);

  const statusText = useMemo(() => {
    switch (item.confirmed) {
      case ConfirmedTypes.FULLY_CONFIRMED:
        return 'Confirmed';
      case ConfirmedTypes.HOLD_CONFIRMED:
        return 'Hold';
      case ConfirmedTypes.UNCONFIRMED:
        return 'Not Confirmed';
      default:
        return '';
    }
  }, [item.confirmed]);

  const [appointmentTypeColor, textContentColor, textTitleColor] = useMemo(() => {
    const color = getAppointmentTypeColor(appointmentParsed);
    const contentColor = color ? getContrastYIQ(color) : 'text-product-grey-400';
    const titleColor = contentColor === 'text-white' ? 'text-white' : 'text-product-forest-100';

    return [color ? `bg-[${color}]` : 'bg-product-sand-200', contentColor, titleColor];
  }, [item]);

  return (
    <li
      onClick={handleAppointmentClick}
      className={`cursor-pointer mb-3 p-4 rounded-lg ${appointmentTypeColor}`}
    >
      <div className='flex justify-between'>
        <p className={`${textTitleColor} font-semibold text-lg mb-2`}>
          {item.title}
          <span className='ml-4 font-light text-base'>{formatDate}</span>
        </p>

        <div className='flex gap-2'>
          {item.athenaAppointmentId && (
            <div>
              <img src={AthenaIcon} alt='Synced to Athena' width={20} height={20} />
            </div>
          )}

          {item.googleCalendarEventId && (
            <div>
              <img
                src={GCalIcon}
                alt='Synced to Google Calendar'
                aria-label='Synced to Google Calendar'
                width={20}
                height={20}
              />
            </div>
          )}
        </div>
      </div>

      <div className={`grid grid-cols-2 leading-relaxed gap-4 ${textContentColor} text-base`}>
        <div className='leading-relaxed'>
          <p>Patient: {item.patientName || 'N/A'}</p>
          <p>Appointment Type: {item.type || 'N/A'}</p>
          <p>Provider: {item.providerName || 'N/A'}</p>
        </div>
        <div>
          <p className='max-w-xs text-ellipsis overflow-hidden ...'>
            Rooms: {item.rooms.map((room) => room.name).join(', ') || 'N/A'}
          </p>
          <div className='flex'>
            <p>{statusText}</p>
            <span>
              <i className={`pl-1 pi pi-${statusIcon} text-xs`}></i>
            </span>
          </div>
        </div>
      </div>
    </li>
  );
};
