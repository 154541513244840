import { useCallback, useEffect, useMemo, useState } from 'react';
import { loader } from 'react-global-loader';
import { DateTime } from 'luxon';
import { Skeleton } from 'primereact/skeleton';
import { AppointmentFilters, AppointmentList } from '@/components';
import { useAppointments, useProviders, useRooms } from '@/hooks';
import {
  AppointmentFilterResourceItem,
  AppointmentFiltersChange,
  AppointmentsSearch,
  Atria,
} from '@/@types';
import { useAppointmentTypeContext, useToastContext } from '@/contexts';
import { SchedulerLayout } from '@/components/Layouts';
import { useSchedulerSocket } from '@/features/Calendar/hooks';
import { useVisitTypeTemplates } from '@/features/AppointmentActions';

const mappIds = (filters: AppointmentFilterResourceItem[]): string => {
  return filters!.map((m) => m.resourceId)?.join(',');
};

const convertDateToDateTimeObject = (date: Date) => {
  return DateTime.local(date!.getFullYear(), date!.getMonth() + 1, date!.getDate());
};

const getRangeDate = (startDateItem: Date | undefined, endDateItem: Date | undefined) => {
  const initialDate = startDateItem
    ? convertDateToDateTimeObject(startDateItem).startOf('day').toUTC().toString()
    : DateTime.fromFormat('1/1/2021', 'd/m/yyyy').startOf('day').toUTC().toString();
  const lastDate = endDateItem
    ? convertDateToDateTimeObject(endDateItem).endOf('day').toUTC().toString()
    : DateTime.now().plus({ year: 2 }).startOf('day').toUTC().toString();

  return {
    rangeQueryStart: initialDate,
    rangeQueryEnd: lastDate,
  };
};

export function AppointmentsPage() {
  const [appointments, setAppointments] = useState<Atria.Appointment[]>([]);
  const { appointmentTypesOptionsList, getAppointmentTypes } = useAppointmentTypeContext();
  const { providersList, findAllProviders } = useProviders();
  const [skeletonAnimation, setSkeletonAnimation] = useState<'wave' | 'none' | undefined>('wave');
  const [skeletonHidden, setSkeletonHidden] = useState(true);
  const { toast } = useToastContext();
  const [currentDate, setCurrentDate] = useState(new Date());
  const { findAllRooms } = useRooms();
  const { findAllVisitTypeTemplates } = useVisitTypeTemplates();

  const { searchAppointments } = useAppointments();

  useSchedulerSocket(setAppointments, currentDate, toast);

  const initialLoad = useCallback(async () => {
    loader.show();
    try {
      await Promise.all([
        findAllRooms(),
        findAllProviders(),
        getAppointmentTypes(),
        findAllVisitTypeTemplates(),
      ]);
      loader.hide();
    } catch (error) {
      toast?.current?.show({
        className: 'text-xl opacity-100',
        severity: 'error',
        summary: 'Failed to load important data',
        detail: `Reload the page. If error persists, message Slack channel #scheduler-buildout or contact Teresa Rufin`,
        life: 60000,
      });
    }
  }, [findAllProviders, findAllRooms, findAllVisitTypeTemplates, getAppointmentTypes, toast]);

  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  const sortedAppointments = useMemo(
    () => appointments.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()),
    [appointments]
  );

  const listAppointments = useCallback(
    async (filters: AppointmentFiltersChange) => {
      const payload: AppointmentsSearch = {
        ...(filters.appointmentTypes?.length
          ? { appointmentTypeIds: mappIds(filters.appointmentTypes) }
          : {}),
        ...(filters.providers?.length ? { providersIds: mappIds(filters.providers) } : {}),
        ...(filters.patientId ? { patientsIds: filters.patientId } : {}),
        ...getRangeDate(filters.startDate, filters.endDate),
      };

      const appointmentList = await searchAppointments(payload);
      setAppointments(appointmentList);
    },
    [searchAppointments]
  );

  const onResourcesChange = useCallback(
    async (filters: AppointmentFiltersChange) => {
      setCurrentDate(filters.startDate!);
      try {
        setSkeletonAnimation('wave');
        setSkeletonHidden(false);
        await listAppointments(filters);
      } catch (error) {
        setAppointments([]);
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to get appointments',
          life: 3000,
        });
      } finally {
        setSkeletonHidden(true);
      }
    },
    [listAppointments, toast]
  );

  return (
    <SchedulerLayout isLocationRequired={false}>
      <div className='flex flex-col lg:flex-row'>
        <div className='min-w-[450px] lg:max-w-[450px] p-6 lg:ml-5'>
          <AppointmentFilters
            providersOptionsList={providersList.map((i) => ({
              resourceId: i.resourceId.replace('provider-', ''),
              resourceTitle: i.resourceTitle,
            }))}
            appointmentTypesOptionsList={appointmentTypesOptionsList.map((i) => ({
              resourceId: i.id.toString(),
              resourceTitle: i.name,
            }))}
            onResourcesChange={onResourcesChange}
          />
        </div>
        <div className={'m-4 lg:w-2/4 lg:max-w-7xl lg:max-h-[calc(100vh-8rem)]'}>
          <h1 className='font-sans text-kelp font-semibold text-2xl mt-4'>
            Results {appointments?.length ? `(${appointments.length})` : ''}
          </h1>
          {skeletonHidden ? (
            <AppointmentList appointmentList={sortedAppointments} classNames='shadow-lg mt-5' />
          ) : (
            <div className='m-4 w-full'>
              <Skeleton
                className='flex lg:max-w-7xl h-[350px]'
                animation={skeletonAnimation}
              ></Skeleton>
            </div>
          )}
        </div>
      </div>
    </SchedulerLayout>
  );
}
