import classNames from 'classnames';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { InputSwitch } from 'primereact/inputswitch';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { loader } from 'react-global-loader';
import { Controller, useForm } from 'react-hook-form';

import {
  AppointmentType,
  AppointmentWithDate,
  AppointmentWithResourcesList,
  AthenaDepartment,
  Atria,
  ConfirmedTypes,
  Provider,
  Resources,
  SelectOption,
} from '@/@types';
import { Permission, useAuthContext, useToastContext, useLocation } from '@/contexts';
import { DateTimeHelper, SchedulerHelper } from '@/helpers';
import { useSchedulerDuplicateAppointment } from '@/hooks';
import { appointmentsService } from '@/services';
import {
  checkIfEnableSchedulerAthenaSync,
  confirmedTypesOptions,
  isAddressRequired,
  isExternalType,
} from '@/utils/appointmentUtils';
import { Accordion, AccordionItem, SearchPatientByNameOrId } from '@/components';
import { SearchAddressAutocomplete } from '@/features/AppointmentActions/components';
import {
  AppointmentFormWithTimeSchemaResolver,
  AppointmentWithTimeFormType,
} from './AppointmentFormSchema';
import { env } from '@/utils/constants';
import { ConfirmedStatus } from './ConfirmedStatus';
import { FormField } from './FormField';
import {
  getAppointmentEndTime,
  getAppointmentStartTime,
  getDateToLocaleString,
  filterRoomsByPermission,
  filterAppointmentTypesByPermission,
  mountAppointmentObject,
  getAppointmentGoogleTitle,
} from '../../utils';
import { SyncHelper } from '@/helpers/syncHelper';

type NewAppointmentInformation = {
  start?: Date;
  end?: Date;
  resources?: Resources[];
};

type CreateAppointmentFormProps = {
  rooms: SelectOption[];
  providers: Provider[];
  newAppointmentInfo?: NewAppointmentInformation;
  onCancel: () => void;
  onSave: (appointment: Atria.Appointment) => void;
  defaultValues?: AppointmentWithDate | AppointmentWithResourcesList;
  appointmentTypes: AppointmentType[];
};

export const CreateAppointmentForm = ({
  rooms,
  providers,
  newAppointmentInfo,
  defaultValues,
  appointmentTypes,
  onCancel,
  onSave,
}: CreateAppointmentFormProps) => {
  const {
    getAthenaDepartments,
    getAthenaDepartmentDefault,
    getAthenaDepartmentByName,
    selectedRegion,
  } = useLocation();
  const [personnelProviders, setPersonnelProviders] = useState<SelectOption[]>([]);
  const [nonPersonnelProviders, setNonPersonnelProviders] = useState<SelectOption[]>([]);
  const [canSyncToAthena, setCanSyncToAthena] = useState(false);
  const [roomsPreviousLength, setRoomsPreviousLength] = useState<number>(0);
  const [disabledRooms, setDisabledRooms] = useState<Set<number>>(new Set());
  const { control, formState, watch, setValue, getValues, handleSubmit, reset, setFocus } =
    useForm<AppointmentWithTimeFormType>({ resolver: AppointmentFormWithTimeSchemaResolver });
  const { toast } = useToastContext();

  const providersSelected = watch('additionalProvidersSelected');
  const primaryProviderSelected = watch('primaryProviderSelected');
  const liaisonProviderSelected = watch('liaisonProviderSelected');
  const resourcesSelected = watch('resourcesSelected');
  const address = watch('address');
  const appointmentType = watch('appointmentType');
  const appointmentConfirmed = watch('appointmentConfirmed');
  const patient = watch('patient');
  const appointmentDate = watch('date');
  const appointmentStartTime = watch('start');
  const roomsSelected = watch('roomsSelected');
  const athenaDepartment = watch('athenaDepartment');
  const syncToAthena = watch('syncToAthena');
  const syncToGoogle = watch('syncToGoogle');
  const title = watch('title');
  const { getDuplicatedAppointmentData } = useSchedulerDuplicateAppointment();

  const { hasPermission } = useAuthContext();

  const roomsOptions = useMemo(() => {
    return filterRoomsByPermission(rooms, hasPermission(Permission['VIEW_ROOMS:TELE']));
  }, [rooms, hasPermission]);

  const appointmentTypesOptions = useMemo(() => {
    return filterAppointmentTypesByPermission(
      appointmentTypes,
      hasPermission(Permission['VIEW_TYPES:TELE'])
    );
  }, [appointmentTypes, hasPermission]);

  const appointmentStartDate = useMemo(() => {
    return getDateToLocaleString(newAppointmentInfo?.start);
  }, [newAppointmentInfo?.start]);

  const initialAppointmentStartTime = useMemo(() => {
    return getAppointmentStartTime(
      newAppointmentInfo && newAppointmentInfo.start && newAppointmentInfo.end
        ? newAppointmentInfo.start
        : undefined
    );
  }, [newAppointmentInfo]);

  const initialAppointmentEndTime = useMemo(() => {
    return getAppointmentEndTime(
      newAppointmentInfo && newAppointmentInfo.start && newAppointmentInfo.end
        ? newAppointmentInfo.end
        : undefined
    );
  }, [newAppointmentInfo]);

  const filteredProviders = useMemo(() => {
    return personnelProviders.filter(
      (prov) => prov.id !== primaryProviderSelected?.id && prov.id !== liaisonProviderSelected?.id
    );
  }, [liaisonProviderSelected?.id, personnelProviders, primaryProviderSelected?.id]);

  const handleCancelFormButton = useCallback(() => {
    reset();
    onCancel();
  }, [reset, onCancel]);

  const handleOnPatientSelect = useCallback(
    (data: Atria.FindAllPatients.Response[0] | null) => {
      if (!data) {
        return setValue('patient', null);
      }
      setValue('patient', {
        id: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        firstNameUsed: data.firstNameUsed,
        patientName: data.patientName,
        primaryProviderId: data.primaryProvider?.id || null,
      });
    },
    [setValue]
  );

  const removePreviousAppointmentTypeDefaultRoom = useCallback(() => {
    const appointmentTypesSelected = watch('appointmentType');

    if (!appointmentTypesSelected) {
      return;
    }

    const previousDefaultRoomsIds: Set<number> = new Set();

    appointmentTypesSelected.defaultRooms?.forEach(({ roomId, restricted }) => {
      if (restricted) {
        setDisabledRooms((prev) => {
          prev.delete(roomId);
          return prev;
        });
      }

      previousDefaultRoomsIds.add(roomId);
    });

    const roomsSelectedWithoutPreviousDefaultRoom = roomsSelected.filter(
      (room) => !previousDefaultRoomsIds.has(room.id)
    );
    setValue('roomsSelected', roomsSelectedWithoutPreviousDefaultRoom);
  }, [setValue, watch, roomsSelected]);

  const removePreviousAppointmentTypeDefaultResource = useCallback(() => {
    const appointmentTypesSelected = watch('appointmentType') as AppointmentType;

    if (!appointmentTypesSelected || !selectedRegion) {
      return;
    }

    const previousDefaultResourceIds: Set<number> = new Set();

    appointmentTypesSelected.defaultResources
      ?.filter((resource) => resource.locationId === selectedRegion.id)
      .forEach(({ providerId }) => {
        previousDefaultResourceIds.add(providerId);
      });

    const newSelectedEquipments = resourcesSelected?.filter(
      (resource) => !previousDefaultResourceIds.has(resource.id)
    );

    return setValue('resourcesSelected', newSelectedEquipments);
  }, [setValue, watch, resourcesSelected, selectedRegion]);

  const addAppointmentTypeDefaultResource = useCallback(
    (newType: AppointmentType) => {
      if (!newType) {
        return;
      }

      const defaultResourceIds: Set<number> = new Set();

      newType.defaultResources
        ?.filter((resource) => resource.locationId === selectedRegion?.id)
        .forEach(({ providerId }) => {
          defaultResourceIds.add(providerId);
        });

      const newSelectedResourceIds = new Set([
        ...(resourcesSelected?.map((resource) => resource.id) || []),
        ...defaultResourceIds.values(),
      ]);

      const newSelectedEquipments = nonPersonnelProviders.filter((r) =>
        newSelectedResourceIds.has(r.id)
      );
      return setValue('resourcesSelected', newSelectedEquipments);
    },
    [nonPersonnelProviders, setValue, resourcesSelected, selectedRegion]
  );

  const updateAppointmentTitle = useCallback(
    (newType: AppointmentType) => {
      if (!env.APP_FEATURE_FLAGS.IS_TO_ENABLE_APPOINTMENT_TITLES || !newType) {
        return;
      }

      const newTitle = newType.title;
      if (newTitle) {
        setValue('title', newTitle);
      }
    },
    [setValue]
  );

  const updateHomeDepartmentByProvider = useCallback(
    (selectedPrimaryProviderId: number) => {
      const selectedPrimaryProviderDepartment = providers.find(
        (i) => i.id === selectedPrimaryProviderId
      )!.homeDepartment;

      const selectedPrimaryProviderAthenaDepartment = selectedPrimaryProviderDepartment
        ? getAthenaDepartmentByName(selectedPrimaryProviderDepartment)
        : getAthenaDepartmentDefault();

      setValue('athenaDepartment', selectedPrimaryProviderAthenaDepartment);
    },
    [getAthenaDepartmentByName, getAthenaDepartmentDefault, providers, setValue]
  );

  const addAppointmentTypeDefaultRoom = useCallback(
    (newType: AppointmentType) => {
      if (!newType) {
        return;
      }

      const rSelected = getValues('roomsSelected');

      const restrictedRoomsIds: Set<number> = new Set();
      const defaultRoomsIds: Set<number> = new Set();

      newType.defaultRooms?.forEach(({ roomId, restricted }) => {
        if (restricted) {
          restrictedRoomsIds.add(roomId);
        }

        if (
          !rSelected?.some((roomSelected) => {
            return SchedulerHelper.shouldSkipRoomForSpecificType(roomSelected.id, newType, roomId);
          })
        ) {
          defaultRoomsIds.add(roomId);
        }
      });

      setDisabledRooms((prev) => {
        return new Set([...prev.values(), ...restrictedRoomsIds.values()]);
      });

      const newSelectedRoomsIds = new Set([
        ...(rSelected?.map((room) => room.id) || []),
        ...defaultRoomsIds.values(),
        ...(rSelected
          ? SchedulerHelper.getRelatedNewRooms(rSelected, rooms, newType, selectedRegion).map(
              (room) => room.id
            )
          : []),
      ]);

      const newSelectedRooms = rooms.filter((r) => newSelectedRoomsIds.has(r.id));
      return setValue('roomsSelected', newSelectedRooms);
    },
    [rooms, setValue, getValues, selectedRegion]
  );

  const handleExternalTypesChange = useCallback(
    (at: AppointmentType | undefined): void => {
      if (isExternalType(at?.name)) {
        const roomsWithoutExternal = roomsSelected.filter(
          (r) => !r.label.toLowerCase().includes('external')
        );
        const externalRoom = roomsOptions.find((r) => r.label.toLowerCase().includes('external'));

        if (!roomsSelected.length) {
          setValue('roomsSelected', [{ id: externalRoom!.id, label: externalRoom!.label }]);
        } else {
          setValue('roomsSelected', [
            ...roomsWithoutExternal,
            { id: externalRoom!.id, label: externalRoom!.label },
          ]);
        }
      }
    },
    [roomsSelected, roomsOptions, setValue]
  );

  const handleAppointmentTypeChange = useCallback(
    async (newType: AppointmentType) => {
      if (!isAddressRequired(newType?.name)) setValue('address', null);

      handleExternalTypesChange(newType);
      removePreviousAppointmentTypeDefaultRoom();
      removePreviousAppointmentTypeDefaultResource();
      addAppointmentTypeDefaultRoom(newType);
      addAppointmentTypeDefaultResource(newType);
      updateAppointmentTitle(newType);
      setValue('appointmentType', newType);
    },
    [
      handleExternalTypesChange,
      addAppointmentTypeDefaultRoom,
      removePreviousAppointmentTypeDefaultRoom,
      addAppointmentTypeDefaultResource,
      removePreviousAppointmentTypeDefaultResource,
      updateAppointmentTitle,
      setValue,
    ]
  );

  const handleAthenaDepartmentChange = useCallback(
    async (department: AthenaDepartment) => {
      setValue('athenaDepartment', department);
    },
    [setValue]
  );

  const handleDisabledRoomsOptions = useCallback(
    (option: any): boolean => {
      return disabledRooms.has(option.id);
    },
    [disabledRooms]
  );

  const onSubmit = useCallback(
    async (data: AppointmentWithTimeFormType) => {
      loader.show();

      try {
        const createdAppointment = await mountAppointmentObject({
          addressId: address?.id,
          additionalProviders: data.additionalProvidersSelected,
          appointmentType: data.appointmentType
            ? {
                id: data.appointmentType?.id,
                label: data.appointmentType?.name,
              }
            : null,
          athenaDepartmentId: data.athenaDepartment?.departmentId,
          confirmed: data.appointmentConfirmed as ConfirmedTypes,
          date: data.date,
          description: data.description,
          end: data.end,
          liaison: data.liaisonProviderSelected,
          parentId: data.parentId,
          patient: data.patient,
          primaryProvider: data.primaryProviderSelected,
          resources: data.resourcesSelected,
          rooms: data.roomsSelected,
          start: data.start,
          syncToAthena: data.syncToAthena,
          syncToGoogle: data.syncToGoogle,
          title: data.title,
          googleCalendarTitle: data.googleCalendarTitle,
        });

        const { data: newAppointment } = createdAppointment.parentId
          ? await appointmentsService.duplicateAppointment(createdAppointment)
          : await appointmentsService.createAppointment(createdAppointment);

        reset();
        onSave(newAppointment);
        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Appointment created successfully',
          life: 3000,
        });
      } catch (error: any) {
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: error.response?.data?.message || 'Failed to create appointment',
          life: 3000,
        });
      } finally {
        loader.hide();
      }
    },
    [address?.id, onSave, reset, toast]
  );

  useEffect(() => {
    setFocus('title');
  }, [setFocus]);

  useEffect(() => {
    if (!roomsSelected || !roomsSelected.length || !appointmentType) return;

    if (roomsSelected.length <= roomsPreviousLength) {
      setRoomsPreviousLength(roomsSelected.length);
      return;
    }

    const newValue = SchedulerHelper.getRelatedNewRooms(
      roomsSelected,
      rooms,
      appointmentType,
      selectedRegion
    );
    setValue('roomsSelected', newValue);
    setRoomsPreviousLength(newValue.length);
  }, [
    roomsSelected,
    rooms,
    roomsPreviousLength,
    setValue,
    setRoomsPreviousLength,
    appointmentType,
    selectedRegion,
  ]);

  // Set end time when an appointment type has a duration
  useEffect(() => {
    if (!syncToAthena) {
      return;
    }
    const matchedAppointmentType: AppointmentType | undefined = appointmentTypes.find(
      (type) => type.id === appointmentType?.id
    );
    if (matchedAppointmentType && matchedAppointmentType.duration) {
      const formattedStart = DateTimeHelper.returnDateTimeForAppointmentStartTime(
        appointmentDate,
        appointmentStartTime
      );
      const endTime: DateTime = formattedStart.plus({ minutes: matchedAppointmentType.duration });
      setValue('end', endTime.toFormat('T'));
    }
  }, [
    appointmentStartTime,
    appointmentDate,
    appointmentType?.id,
    appointmentTypes,
    setValue,
    syncToAthena,
  ]);

  // set initial default athena department
  useEffect(() => {
    if (!athenaDepartment) {
      setValue('athenaDepartment', getAthenaDepartmentDefault());
    }
  }, [athenaDepartment, getAthenaDepartmentDefault, setValue]);

  useEffect(() => {
    setValue('date', appointmentStartDate);
    setValue('end', initialAppointmentEndTime);
  }, [initialAppointmentEndTime, appointmentStartDate, initialAppointmentStartTime, setValue]);

  useEffect(() => {
    if (newAppointmentInfo && newAppointmentInfo.resources) {
      const resourcesRooms =
        newAppointmentInfo.resources
          .filter((r) => r.resourceId?.includes('room'))
          .map((r) => Number(r.resourceId.split('-')[1])) || [];
      const resourcesProviders =
        newAppointmentInfo.resources
          .filter((r) => r.resourceId?.includes('provider'))
          .map((r) => Number(r.resourceId.split('-')[1])) || [];
      const resourcesEquipment =
        newAppointmentInfo.resources
          .filter((r) => r.resourceId?.includes('equipment'))
          .map((r) => Number(r.resourceId.split('-')[1])) || [];
      const [selectedPrimaryProvider, ...appointmentProviders] = personnelProviders.filter(
        (provider) => resourcesProviders.find((r) => r === provider.id)
      );

      const selectedRooms = rooms.filter((room) => resourcesRooms.find((r) => r === room.id));
      setValue('roomsSelected', selectedRooms);

      const selectedEquipment = nonPersonnelProviders.filter((e) =>
        resourcesEquipment.find((r) => r === e.id)
      );

      if (selectedPrimaryProvider) {
        setValue('primaryProviderSelected', selectedPrimaryProvider);
      }
      if (appointmentProviders && appointmentProviders.length > 0) {
        setValue('additionalProvidersSelected', appointmentProviders);
      }
      if (selectedEquipment && selectedEquipment.length > 0) {
        setValue('resourcesSelected', selectedEquipment);
      }
    }
  }, [newAppointmentInfo, rooms, setValue, personnelProviders, nonPersonnelProviders]);

  useEffect(() => {
    if (primaryProviderSelected?.id) {
      updateHomeDepartmentByProvider(primaryProviderSelected?.id);
    }
  }, [primaryProviderSelected, updateHomeDepartmentByProvider]);

  useEffect(() => {
    const personnel: SelectOption[] = [];
    const nonPersonnel: SelectOption[] = [];

    providers.forEach((provider) => {
      if (provider.type === 'Person') {
        personnel.push({ id: provider.id, label: provider.name });
      } else {
        nonPersonnel.push({ id: provider.id, label: provider.name });
      }
    }, []);

    setPersonnelProviders(personnel);
    setNonPersonnelProviders(nonPersonnel);
  }, [providers]);

  useEffect(() => {
    if (defaultValues) {
      const data = getDuplicatedAppointmentData(defaultValues);
      setValue('parentId', data.parentId);
      setValue('title', data.title);
      setValue('description', data.description);
      setValue('date', data.date);
      setValue('start', data.start);
      setValue('end', data.end);
      setValue('patient', data.patient);
      setValue('roomsSelected', data.roomsSelected);
      setValue('primaryProviderSelected', data.primaryProviderSelected);
      setValue('additionalProvidersSelected', data.additionalProvidersSelected);
      setValue('liaisonProviderSelected', data.liaisonProviderSelected);
      setValue('resourcesSelected', data.resourcesSelected);
      setValue(
        'appointmentType',
        appointmentTypes.find((type) => type.id === defaultValues.typeId)
      );
      setValue('appointmentConfirmed', data.appointmentConfirmed);
      setValue('athenaDepartment', getAthenaDepartmentDefault());
    }
    setValue('appointmentConfirmed', ConfirmedTypes.HOLD_CONFIRMED);
    setValue('atriaAppointment', true);
  }, [
    appointmentTypes,
    defaultValues,
    getDuplicatedAppointmentData,
    setValue,
    getAthenaDepartmentDefault,
  ]);

  // Alert user if the appointment will sync to Athena
  useEffect(() => {
    const isValid = checkIfEnableSchedulerAthenaSync({
      date: appointmentDate,
      providerId: primaryProviderSelected?.id,
      status: appointmentConfirmed as ConfirmedTypes,
      type: appointmentType?.name,
      patientId: patient?.id,
    });
    setCanSyncToAthena(isValid);
  }, [appointmentConfirmed, appointmentType, primaryProviderSelected, patient, appointmentDate]);

  useEffect(() => {
    if (title && appointmentType && primaryProviderSelected && patient) {
      setValue(
        'googleCalendarTitle',
        getAppointmentGoogleTitle(title, primaryProviderSelected, appointmentType, patient)
      );
    }
  }, [title, appointmentType, primaryProviderSelected, patient]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
      <div className='flex mt-4'>
        <Controller
          control={control}
          name='date'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={true}
              label='Date'
              labelRef='date'
            >
              <Calendar
                {...field}
                id='date'
                placeholder='Date'
                dateFormat='mm/dd/yy'
                locale='en'
                value={DateTimeHelper.formatDateToUseInCalendar(field.value)}
                className={classNames('h-[37px]', {
                  'border-rust': !!fieldState.error,
                })}
                inputClassName={classNames({
                  'border-rust': !!fieldState.error,
                })}
              />
            </FormField>
          )}
        />
        <Controller
          control={control}
          name='start'
          defaultValue={initialAppointmentStartTime}
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={false}
              isRequired={true}
              label='Start'
              labelRef='start'
            >
              <InputText
                {...field}
                type='time'
                className={classNames('h-[37px]', {
                  'border-rust': !!fieldState.error,
                })}
              />
            </FormField>
          )}
        />
        <Controller
          control={control}
          name='end'
          defaultValue={initialAppointmentEndTime}
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={false}
              isRequired={true}
              label='End'
              labelRef='end'
            >
              <InputText
                disabled={!!syncToAthena}
                {...field}
                type='time'
                className={classNames('h-[37px]', {
                  'border-rust': !!fieldState.error,
                })}
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='appointmentType'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={false}
              label='Appointment Type'
              labelRef='appointmentType'
            >
              <Dropdown
                inputId='appointmentType'
                placeholder='Select an appointment type'
                filter
                optionLabel='name'
                options={appointmentTypesOptions.sort((a, b) => a.name.localeCompare(b.name))}
                onChange={(e) => handleAppointmentTypeChange(e.value)}
                value={field.value}
                focusInputRef={field.ref}
                showClear={true}
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='title'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={true}
              label='Title'
              labelRef='title'
            >
              <InputText
                {...field}
                id='title'
                placeholder='Title'
                value={field.value || ''}
                className={classNames('h-[37px]', {
                  'border-rust': !!fieldState.error,
                })}
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='description'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={false}
              label='Description'
              labelRef='description'
            >
              <InputTextarea
                {...field}
                id='description'
                placeholder='Description'
                value={field.value || ''}
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <SearchPatientByNameOrId
          defaultFirstName={defaultValues?.firstName}
          defaultLastName={defaultValues?.lastName}
          defaultFirstNameUsed={defaultValues?.firstNameUsed}
          defaultPatientId={defaultValues?.patientId}
          onSelect={handleOnPatientSelect}
        />
      </div>

      {env.APP_FEATURE_FLAGS.IS_TO_ENABLE_ADDRESS_APPOINTMENT &&
        isAddressRequired(appointmentType?.name) && (
          <SearchAddressAutocomplete
            address={address}
            setValue={(value) => setValue('address', value)}
          />
        )}

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='roomsSelected'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={true}
              label='Rooms'
              labelRef='roomsSelected'
            >
              <MultiSelect
                inputId='roomsSelected'
                placeholder='Select rooms'
                filter
                optionLabel='label'
                options={roomsOptions.sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(e) => field.onChange(e.value)}
                onBlur={field.onBlur}
                value={field.value}
                optionDisabled={handleDisabledRoomsOptions}
                className={classNames({
                  'border-rust': !!fieldState.error,
                })}
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='primaryProviderSelected'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={false}
              label='Primary Provider'
              labelRef='primaryProviderSelected'
            >
              <Dropdown
                showClear
                inputId='primaryProviderSelected'
                placeholder='Select the primary provider'
                filter
                optionLabel='label'
                options={personnelProviders.sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(e: DropdownChangeEvent) => {
                  const provider = e.target.value;
                  field.onChange(provider);
                  setValue(
                    'additionalProvidersSelected',
                    providersSelected?.filter((i) => i.id !== provider?.id)
                  );
                  provider && updateHomeDepartmentByProvider(provider.id);
                }}
                value={field.value}
                focusInputRef={field.ref}
              />
            </FormField>
          )}
        />
      </div>

      {primaryProviderSelected?.id && (
        <div className='flex mt-4'>
          <Controller
            control={control}
            name='athenaDepartment'
            render={({ field, fieldState }) => (
              <FormField
                error={fieldState.error?.message}
                firstColumn={true}
                isRequired={false}
                label='Athena Department'
                labelRef='athenaDepartment'
              >
                <Dropdown
                  inputId='athenaDepartment'
                  placeholder='Select a department'
                  filter
                  optionLabel='name'
                  options={getAthenaDepartments()}
                  onChange={(e) => handleAthenaDepartmentChange(e.value)}
                  value={field.value}
                  focusInputRef={field.ref}
                  showClear={true}
                />
              </FormField>
            )}
          />
        </div>
      )}

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='liaisonProviderSelected'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={false}
              label='Liaison'
              labelRef='liaisonProviderSelected'
            >
              <Dropdown
                showClear
                inputId='liaisonProviderSelected'
                placeholder='Select the liaison'
                filter
                optionLabel='label'
                options={
                  primaryProviderSelected
                    ? personnelProviders
                        .filter((p) => p.id !== primaryProviderSelected?.id)
                        .sort((a, b) => a.label.localeCompare(b.label))
                    : personnelProviders.sort((a, b) => a.label.localeCompare(b.label))
                }
                onChange={(e: DropdownChangeEvent) => {
                  const provider = e.target.value;
                  field.onChange(provider);
                  setValue(
                    'additionalProvidersSelected',
                    providersSelected?.filter((i) => i.id !== provider?.id)
                  );
                }}
                value={field.value}
                focusInputRef={field.ref}
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <Accordion>
          <AccordionItem
            id='aditionalClinitianAndResources'
            title='Add additional clinician(s) & resources'
            formMode={true}
          >
            <div className='flex mt-4'>
              <Controller
                control={control}
                name='additionalProvidersSelected'
                render={({ field, fieldState }) => (
                  <FormField
                    error={fieldState.error?.message}
                    firstColumn={true}
                    isRequired={false}
                    label='Additional Clinician'
                    labelRef='additionalProvidersSelected'
                  >
                    <MultiSelect
                      inputId='additionalProvidersSelected'
                      placeholder='Select additional staff'
                      filter
                      optionLabel='label'
                      options={filteredProviders.sort((a, b) => a.label.localeCompare(b.label))}
                      onChange={(e) => field.onChange(e.value)}
                      onBlur={field.onBlur}
                      value={field.value}
                    />
                  </FormField>
                )}
              />
            </div>

            <div className='flex mt-4'>
              <Controller
                control={control}
                name='resourcesSelected'
                render={({ field, fieldState }) => (
                  <FormField
                    error={fieldState.error?.message}
                    firstColumn={true}
                    isRequired={false}
                    label='Resources'
                    labelRef='resourcesSelected'
                  >
                    <MultiSelect
                      inputId='resourcesSelected'
                      placeholder='Select resources'
                      filter
                      optionLabel='label'
                      options={nonPersonnelProviders.sort((a, b) => a.label.localeCompare(b.label))}
                      onChange={(e) => field.onChange(e.value)}
                      onBlur={field.onBlur}
                      value={field.value}
                    />
                  </FormField>
                )}
              />
            </div>
          </AccordionItem>
        </Accordion>
      </div>

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='appointmentConfirmed'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={false}
              label='Status:'
              labelRef='appointmentConfirmed'
            >
              <ConfirmedStatus
                value={field.value ?? ConfirmedTypes.HOLD_CONFIRMED}
                onChange={(e) => field.onChange(e.value)}
                optionLabel='name'
                options={confirmedTypesOptions}
              />
            </FormField>
          )}
        />
      </div>

      {env.APP_FEATURE_FLAGS.IS_TO_ENABLE_SYNC_FLAGS && (
        <div className='flex mt-4 gap-6'>
          <Controller
            control={control}
            name='syncToAthena'
            render={({ field, fieldState }) => (
              <FormField
                disabled={!canSyncToAthena}
                error={fieldState.error?.message}
                firstColumn={true}
                inline={true}
                isRequired={false}
                label='Add to Athena'
                labelRef='syncAthena'
                reverted={true}
              >
                <InputSwitch
                  inputId='syncAthena'
                  checked={field.value ?? false}
                  onChange={(e) => field.onChange(e.value)}
                  disabled={!canSyncToAthena}
                />
              </FormField>
            )}
          />

          {env.APP_FEATURE_FLAGS.IS_TO_ENABLE_GOOGLE_SYNC_FLAG && (
            <Controller
              control={control}
              name='syncToGoogle'
              render={({ field, fieldState }) => (
                <FormField
                  disabled={!primaryProviderSelected && !providersSelected?.length}
                  error={fieldState.error?.message}
                  firstColumn={true}
                  inline={true}
                  isRequired={false}
                  label='Add to Google Calendar'
                  labelRef='syncGoogle'
                  reverted={true}
                >
                  <InputSwitch
                    disabled={!primaryProviderSelected && !providersSelected?.length}
                    inputId='syncGoogle'
                    checked={field.value ?? false}
                    onChange={(e) => field.onChange(e.value)}
                  />
                </FormField>
              )}
            />
          )}
        </div>
      )}

      {syncToGoogle && (
        <div className='flex mt-4'>
          <Controller
            control={control}
            name='googleCalendarTitle'
            render={({ field, fieldState }) => (
              <FormField
                error={fieldState.error?.message}
                firstColumn={true}
                isRequired={false}
                label='Title (Google)'
                labelRef='title'
              >
                <InputText
                  {...field}
                  id='googleCalendarTitle'
                  placeholder='Title (Google)'
                  value={field.value || ''}
                  className={classNames('h-[37px]', {
                    'border-rust': !!fieldState.error,
                  })}
                />
              </FormField>
            )}
          />
        </div>
      )}

      <p className='text-[#757575] text-[11px] font-medium mt-[11px] ml-1'>
        {SyncHelper.messageSyncGoogleAndAthena}
      </p>

      <div className='w-full flex flex-col gap-2 mt-4'>
        <Button
          label='Save'
          size='small'
          type='submit'
          className='w-full'
          disabled={Object.values(formState.errors).length > 0}
        />
        <Button
          label='Cancel'
          size='small'
          type='reset'
          className='w-full'
          onClick={handleCancelFormButton}
          outlined
        />
      </div>
    </form>
  );
};
