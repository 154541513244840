import {
  Address,
  AppointmentType,
  AppointmentWithDate,
  AppointmentWithResourcesList,
  AthenaDepartment,
  Atria,
  ConfirmedTypes,
  Origin,
  SelectOption,
} from '@/@types';
import { Permission, useAuthContext, useLocation, useToastContext } from '@/contexts';
import { appointmentsService } from '@/services';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { loader } from 'react-global-loader';
import { Controller, useForm } from 'react-hook-form';

import { SchedulerHelper } from '@/helpers';
import {
  checkIfEnableSchedulerAthenaSync,
  confirmedTypesOptions,
  isAddressRequired,
  isExternalType,
} from '@/utils';
import { Accordion, AccordionItem, SearchPatientByNameOrId } from '@/components';
import { SearchAddressAutocomplete } from '@/features/AppointmentActions/components';
import {
  EditAppointmentWithTimeFormResolver,
  EditAppointmentWithTimeFormType,
} from './EditAppointmentFormSchema';
import { env } from '@/utils/constants';
import { ConfirmedStatus } from './ConfirmedStatus';
import { FormField } from './FormField';
import {
  filterAppointmentTypesByPermission,
  filterRoomsByPermission,
  getAppointmentEndTime,
  getAppointmentStartTime,
  getDateToLocaleString,
  mountAppointmentObject,
} from '../../utils';
import { SyncHelper } from '@/helpers/syncHelper';

const now = DateTime.now();

type EditAppointmentFormProps = {
  rooms: SelectOption[];
  personnelProviders: SelectOption[];
  nonPersonnelProviders: SelectOption[];
  appointment: AppointmentWithDate | AppointmentWithResourcesList;
  appointmentTypes: AppointmentType[];
  onCancel: () => void;
  onUpdate: () => void;
};

export const EditAppointmentForm = ({
  rooms,
  personnelProviders,
  nonPersonnelProviders,
  appointment,
  appointmentTypes,
  onCancel,
  onUpdate,
}: EditAppointmentFormProps) => {
  const { control, formState, watch, setValue, getValues, handleSubmit, reset } =
    useForm<EditAppointmentWithTimeFormType>({ resolver: EditAppointmentWithTimeFormResolver });
  const { toast } = useToastContext();
  const { hasPermission } = useAuthContext();
  const { getAthenaDepartments, getAthenaDepartmentDefault, selectedRegion } = useLocation();

  const additionalProvidersSelected = watch('additionalProvidersSelected');
  const primaryProviderSelected = watch('primaryProviderSelected');
  const liaisonProviderSelected = watch('liaisonProviderSelected');
  const resourcesSelected = watch('resourcesSelected');
  const appointmentType = watch('appointmentType');
  const appointmentConfirmed = watch('appointmentConfirmed');
  const patient = watch('patient');
  const appointmentDate = watch('date');
  const roomsSelected = watch('roomsSelected');
  const address = watch('address');
  const athenaDepartment = watch('athenaDepartment');
  const [roomsPreviousLength, setRoomsPreviousLength] = useState<number>(0);
  const [disabledRooms, setDisabledRooms] = useState<Set<number>>(() => {
    if (!appointment || !appointment.typeId) return new Set();

    const type = appointmentTypes.find((x) => x.id === appointment.typeId);
    if (!type || !type.defaultRooms) return new Set();

    const restrictedRoomIds = type.defaultRooms
      .filter((room) => room.restricted)
      .map((room) => room.roomId);

    return new Set(restrictedRoomIds);
  });
  const [canSyncToAthena, setCanSyncToAthena] = useState(false);
  const [syncedToAthena, setSyncedToAthena] = useState(false);

  const appointmentStartDate = useMemo(() => {
    return getDateToLocaleString(appointment?.date);
  }, [appointment]);

  const initialAppointmentStartTime = useMemo(() => {
    return getAppointmentStartTime(appointment?.date);
  }, [appointment]);

  const appointmentEndTime = useMemo(() => {
    return getAppointmentEndTime(appointment?.end);
  }, [appointment]);

  const roomsOptions = useMemo(() => {
    return filterRoomsByPermission(rooms, hasPermission(Permission['VIEW_ROOMS:TELE']));
  }, [rooms, hasPermission]);

  const appointmentTypesOptions = useMemo(() => {
    return filterAppointmentTypesByPermission(
      appointmentTypes,
      hasPermission(Permission['VIEW_TYPES:TELE'])
    );
  }, [appointmentTypes, hasPermission]);

  const filteredProviders = useMemo(() => {
    return personnelProviders.filter(
      (prov) => prov.id !== primaryProviderSelected?.id && prov.id !== liaisonProviderSelected?.id
    );
  }, [liaisonProviderSelected?.id, personnelProviders, primaryProviderSelected?.id]);

  const isDateTimeInputDisabled = useMemo(() => {
    if (!appointment) return true;
    const apptDate = DateTime.fromJSDate(appointment.date).toFormat('yyyy-MM-dd');
    const date = DateTime.now().toFormat('yyyy-MM-dd');
    const isYesterday = apptDate < date;
    if (isYesterday) return true;
    const isAthena = appointment.origin === Origin.Athena;
    return isAthena || isYesterday;
  }, [appointment]);

  const handleCancelFormButton = useCallback(() => {
    reset();
    onCancel();
  }, [reset, onCancel]);

  const handleOnPatientSelect = useCallback(
    (data: Atria.FindAllPatients.Response[0] | null) => {
      setValue(
        'patient',
        !data
          ? null
          : {
              id: data.id,
              firstName: data.firstName,
              lastName: data.lastName,
              firstNameUsed: data.firstNameUsed,
              patientName: data.patientName,
              primaryProviderId: data.primaryProvider?.id || null,
            }
      );
    },
    [setValue]
  );

  const removePreviousAppointmentTypeDefaultRoom = useCallback(() => {
    const appointmentTypesSelected = watch('appointmentType');

    if (!appointmentTypesSelected) {
      return;
    }

    const previousDefaultRoomsIds: Set<number> = new Set();

    appointmentTypesSelected.defaultRooms?.forEach(({ roomId, restricted }) => {
      if (restricted) {
        setDisabledRooms((prev) => {
          prev.delete(roomId);
          return prev;
        });
      }

      previousDefaultRoomsIds.add(roomId);
    });

    const rSelected = getValues('roomsSelected');

    const roomsSelectedWithoutPreviousDefaultRoom = rSelected.filter(
      (room) => !previousDefaultRoomsIds.has(room.id)
    );
    setValue('roomsSelected', roomsSelectedWithoutPreviousDefaultRoom);
  }, [setValue, getValues, watch]);

  const addAppointmentTypeDefaultRoom = useCallback(
    (newType: AppointmentType) => {
      if (!newType) {
        return;
      }

      const rSelected = getValues('roomsSelected');

      const restrictedRoomsIds: Set<number> = new Set();
      const defaultRoomsIds: Set<number> = new Set();

      newType.defaultRooms?.forEach(({ roomId, restricted }) => {
        if (restricted) {
          restrictedRoomsIds.add(roomId);
        }

        defaultRoomsIds.add(roomId);
      });

      setDisabledRooms((prev) => {
        return new Set([...prev.values(), ...restrictedRoomsIds.values()]);
      });

      const newSelectedRoomsIds = new Set([
        ...rSelected.map((room) => room.id),
        ...defaultRoomsIds.values(),
        ...SchedulerHelper.getRelatedNewRooms(rSelected, rooms, newType, selectedRegion).map(
          (room) => room.id
        ),
      ]);
      const newSelectedRooms = rooms.filter((r) => newSelectedRoomsIds.has(r.id));
      return setValue('roomsSelected', newSelectedRooms);
    },
    [rooms, setValue, getValues, selectedRegion]
  );

  const removePreviousAppointmentTypeDefaultResource = useCallback(() => {
    const appointmentTypesSelected = watch('appointmentType') as AppointmentType;

    if (!appointmentTypesSelected || !selectedRegion) {
      return;
    }

    const previousDefaultResourceIds: Set<number> = new Set();

    appointmentTypesSelected.defaultResources
      ?.filter((resource) => resource.locationId === selectedRegion.id)
      .forEach(({ providerId }) => {
        previousDefaultResourceIds.add(providerId);
      });

    const newSelectedEquipments = resourcesSelected?.filter(
      (resource) => !previousDefaultResourceIds.has(resource.id)
    );

    return setValue('resourcesSelected', newSelectedEquipments);
  }, [setValue, watch, resourcesSelected, selectedRegion]);

  const addAppointmentTypeDefaultResource = useCallback(
    (newType: AppointmentType) => {
      if (!newType) {
        return;
      }

      const defaultResourceIds: Set<number> = new Set();

      newType.defaultResources
        ?.filter((resource) => resource.locationId === selectedRegion?.id)
        .forEach(({ providerId }) => {
          defaultResourceIds.add(providerId);
        });

      const newSelectedResourceIds = new Set([
        ...(resourcesSelected?.map((resource) => resource.id) || []),
        ...defaultResourceIds.values(),
      ]);

      const newSelectedEquipments = nonPersonnelProviders.filter((r) =>
        newSelectedResourceIds.has(r.id)
      );
      return setValue('resourcesSelected', newSelectedEquipments);
    },
    [nonPersonnelProviders, setValue, resourcesSelected, selectedRegion]
  );

  const updateAppointmentTitle = useCallback(
    (newType: AppointmentType) => {
      if (!env.APP_FEATURE_FLAGS.IS_TO_ENABLE_APPOINTMENT_TITLES || !newType) {
        return;
      }

      const title = newType.title;
      if (title) {
        setValue('title', title);
      }
    },
    [setValue]
  );

  const handleExternalTypesChange = useCallback(
    (at: AppointmentType | undefined): void => {
      if (isExternalType(at?.name)) {
        const roomsWithoutExternal = roomsSelected.filter(
          (r) => !r.label.toLowerCase().includes('external')
        );
        const externalRoom = roomsOptions.find((r) => r.label.toLowerCase().includes('external'));

        if (!roomsSelected.length) {
          setValue('roomsSelected', [{ id: externalRoom!.id, label: externalRoom!.label }]);
        } else {
          setValue('roomsSelected', [
            ...roomsWithoutExternal,
            { id: externalRoom!.id, label: externalRoom!.label },
          ]);
        }
      }
    },
    [roomsSelected, roomsOptions, setValue]
  );

  const handleAppointmentTypeChange = useCallback(
    async (newType: AppointmentType) => {
      if (!isAddressRequired(newType?.name)) setValue('address', null);

      handleExternalTypesChange(newType);
      removePreviousAppointmentTypeDefaultRoom();
      removePreviousAppointmentTypeDefaultResource();
      addAppointmentTypeDefaultRoom(newType);
      addAppointmentTypeDefaultResource(newType);
      updateAppointmentTitle(newType);
      setValue('appointmentType', newType);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      handleExternalTypesChange,
      addAppointmentTypeDefaultRoom,
      removePreviousAppointmentTypeDefaultRoom,
      removePreviousAppointmentTypeDefaultResource,
      updateAppointmentTitle,
      setValue,
    ]
  );

  const handleDisabledRoomsOptions = useCallback(
    (option: any): boolean => {
      return disabledRooms.has(option.id);
    },
    [disabledRooms]
  );

  const onSubmit = useCallback(
    async (data: EditAppointmentWithTimeFormType) => {
      loader.show();
      try {
        const updateAppointmentObj = await mountAppointmentObject({
          additionalProviders: data.additionalProvidersSelected,
          appointmentType: data.appointmentType
            ? {
                id: data.appointmentType?.id,
                label: data.appointmentType?.name,
              }
            : null,
          athenaDepartmentId: data.athenaDepartment?.departmentId,
          confirmed: data.appointmentConfirmed
            ? (data.appointmentConfirmed as ConfirmedTypes)
            : ConfirmedTypes.FULLY_CONFIRMED,
          date: data.date,
          description: data.description,
          end: data.end,
          liaison: data.liaisonProviderSelected,
          parentId: data.parentId,
          patient: data.patient,
          primaryProvider: data.primaryProviderSelected,
          resources: data.resourcesSelected,
          rooms: data.roomsSelected,
          start: data.start,
          title: data.title,
          addressId: address?.id,
          atriaAppointment: appointment.atriaAppointment,
          syncToAthena: data.syncToAthena ?? false,
          syncToGoogle: data.syncToGoogle ?? false,
          address: address as Address,
        });

        await appointmentsService.updateAppointment(
          appointment.appointmentId,
          updateAppointmentObj
        );

        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Appointment updated successfully',
          life: 3000,
        });

        reset();
        onUpdate();
      } catch (error: any) {
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: error?.response?.data?.message || 'Failed to update appointment',
          life: 3000,
        });
      } finally {
        loader.hide();
      }
    },
    [address, appointment.appointmentId, appointment.atriaAppointment, onUpdate, reset, toast]
  );

  const handleAthenaDepartmentChange = useCallback(
    async (department: AthenaDepartment) => {
      setValue('athenaDepartment', department);
    },
    [setValue]
  );

  useEffect(() => {
    if (!roomsSelected || !roomsSelected.length || !appointmentType) return;

    if (roomsSelected.length <= roomsPreviousLength) {
      setRoomsPreviousLength(roomsSelected.length);
      return;
    }

    const newValue = SchedulerHelper.getRelatedNewRooms(roomsSelected, rooms, appointmentType);
    setValue('roomsSelected', newValue);
    setRoomsPreviousLength(newValue.length);
  }, [
    roomsSelected,
    rooms,
    roomsPreviousLength,
    setValue,
    setRoomsPreviousLength,
    appointmentType,
  ]);

  // set initial default athena department
  useEffect(() => {
    if (!athenaDepartment && !appointment.athenaDepartmentId) {
      setValue('athenaDepartment', getAthenaDepartmentDefault());
    } else {
      const selectedDepartment = getAthenaDepartments().find(
        ({ departmentId }) => departmentId === appointment.athenaDepartmentId
      );
      if (appointment.athenaDepartmentId && selectedDepartment) {
        setValue('athenaDepartment', {
          departmentId: selectedDepartment.departmentId,
          name: selectedDepartment.name,
        });
      } else {
        setValue('athenaDepartment', getAthenaDepartmentDefault());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment.athenaDepartmentId, getAthenaDepartmentDefault, getAthenaDepartments, setValue]);

  // Alert user if the appointment will sync to Athena
  useEffect(() => {
    if (!appointment?.athenaAppointmentId) {
      const isValid = checkIfEnableSchedulerAthenaSync({
        providerId: primaryProviderSelected?.id,
        status: appointmentConfirmed as ConfirmedTypes,
        type: appointmentType?.name,
        patientId: patient?.id,
        date: appointmentDate,
      });
      setCanSyncToAthena(isValid);
    }
  }, [
    appointmentConfirmed,
    appointmentType,
    primaryProviderSelected,
    patient,
    appointmentDate,
    appointment?.athenaAppointmentId,
  ]);

  useEffect(() => {
    const loadDefaultValues = async () => {
      if (!appointment) return;
      setValue('date', appointmentStartDate);
      setValue('title', appointment.title);
      setValue('description', appointment.description);
      setValue('atriaAppointment', appointment.atriaAppointment);
      setValue('roomsSelected', appointment.rooms!);
      setValue('syncToAthena', false);
      setValue(
        'additionalProvidersSelected',
        appointment.providers
          ?.filter((p) => p.type === 'ADDITIONAL')
          .map((p) => ({
            id: p.id,
            label: p.name,
          }))
      );
      setValue(
        'resourcesSelected',
        appointment.providers
          ?.filter((p) => p.type === 'EQUIPMENT')
          .map((p) => ({
            id: p.id,
            label: p.name,
          }))
      );
      setValue('appointmentConfirmed', appointment.confirmed);
      if (appointment.provider && appointment.provider.id && appointment.provider.label) {
        setValue('primaryProviderSelected', appointment?.provider);
      }
      const liaisonProvider = appointment.providers?.find((p) => p.type === 'LIAISON');
      if (liaisonProvider) {
        setValue('liaisonProviderSelected', {
          id: liaisonProvider.id,
          label: liaisonProvider.name,
        });
      }
      if (appointment?.typeId) {
        const type = appointmentTypes.find((x) => x.id === appointment.typeId);
        if (type) {
          setValue('appointmentType', type);
        }
      }
      if (appointment.patientId) {
        setValue('patient', {
          id: appointment.patientId,
          firstName: appointment.firstName!,
          lastName: appointment.lastName!,
          firstNameUsed: appointment.firstNameUsed,
          patientName: appointment.patientName!,
          primaryProviderId: appointment.patientPrimaryProviderId,
        });
      }
      if (appointment.athenaAppointmentId) {
        setSyncedToAthena(true);
      }

      if (!appointment.address) return;

      setValue('address', {
        label: `${appointment.addressNumber ?? ''} ${appointment.address}, ${appointment.city ? `${appointment.city}, ` : ''}${appointment.state ?? ''} ${appointment.zipCode ?? ''}, ${appointment.country ?? ''}`,
        address: appointment.address,
        addressDetails: appointment.addressDetails,
        addressNumber: appointment.addressNumber,
        city: appointment.city,
        state: appointment.state,
        zipCode: appointment.zipCode,
        country: appointment.country,
        latitude: appointment.latitude,
        longitude: appointment.longitude,
      });
    };

    loadDefaultValues();
  }, [
    setValue,
    appointment,
    appointmentStartDate,
    initialAppointmentStartTime,
    appointmentEndTime,
    appointmentTypes,
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
      <div className='flex mt-4'>
        <Controller
          control={control}
          name='date'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={true}
              label='Date'
              labelRef='date'
            >
              <Calendar
                {...field}
                inputId='date'
                placeholder='Date'
                dateFormat='mm/dd/yy'
                locale='en'
                value={new Date(field.value)}
                disabled={isDateTimeInputDisabled}
                minDate={now.toJSDate()}
                className={classNames('h-[37px]', {
                  'border-rust': !!fieldState.error,
                })}
                inputClassName={classNames({
                  'border-rust': !!fieldState.error,
                })}
              />
            </FormField>
          )}
        />
        <Controller
          control={control}
          name='start'
          defaultValue={initialAppointmentStartTime}
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={false}
              isRequired={true}
              label='Start'
              labelRef='start'
            >
              <InputText
                type='time'
                className={classNames('h-[37px]', {
                  'border-rust': !!fieldState.error,
                })}
                disabled={isDateTimeInputDisabled}
                step={900}
                {...field}
              />
            </FormField>
          )}
        />
        <Controller
          control={control}
          name='end'
          defaultValue={appointmentEndTime}
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={false}
              isRequired={true}
              label='End'
              labelRef='end'
            >
              <InputText
                type='time'
                className={classNames('h-[37px]', {
                  'border-rust': !!fieldState.error,
                })}
                disabled={isDateTimeInputDisabled}
                step={900}
                {...field}
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='appointmentType'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={false}
              label='Appointment Type'
              labelRef='appointmentType'
            >
              <Dropdown
                inputId='appointmentType'
                placeholder='Select an appointment type'
                filter
                optionLabel='name'
                disabled={!appointment?.atriaAppointment || syncedToAthena}
                options={appointmentTypesOptions.sort((a, b) => a.name.localeCompare(b.name))}
                onChange={(e) => handleAppointmentTypeChange(e.value)}
                value={field.value}
                focusInputRef={field.ref}
                showClear
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='title'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={true}
              label='Title'
              labelRef='title'
            >
              <InputText
                {...field}
                id='title'
                placeholder='Title'
                value={field.value || ''}
                disabled={!appointment?.atriaAppointment}
                className={classNames('h-[37px]', {
                  'border-rust': !!fieldState.error,
                })}
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='description'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={false}
              label='Description'
              labelRef='description'
            >
              <InputTextarea
                {...field}
                id='description'
                placeholder='Description'
                value={field.value || ''}
                disabled={!appointment?.atriaAppointment}
              />
            </FormField>
          )}
        />
      </div>

      {appointment?.atriaAppointment ? (
        <div className='flex mt-4'>
          <SearchPatientByNameOrId
            defaultPatientId={appointment.patientId}
            defaultFirstName={appointment.firstName}
            defaultLastName={appointment.lastName}
            defaultFirstNameUsed={appointment?.firstNameUsed}
            onSelect={handleOnPatientSelect}
            disabled={syncedToAthena}
          />
        </div>
      ) : (
        <div className='flex mt-4'>
          <FormField
            firstColumn={true}
            isRequired={false}
            label='Patient'
            labelRef='patientSelected'
          >
            <InputText readOnly className='w-full ' value={`${appointment.patientName}`} />
          </FormField>
        </div>
      )}

      {env.APP_FEATURE_FLAGS.IS_TO_ENABLE_ADDRESS_APPOINTMENT &&
        isAddressRequired(appointmentType?.name) && (
          <SearchAddressAutocomplete
            address={address}
            setValue={(value) => setValue('address', value)}
          />
        )}

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='roomsSelected'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={appointment.atriaAppointment}
              label='Rooms'
              labelRef='roomsSelected'
            >
              <MultiSelect
                inputId='roomsSelected'
                placeholder='Select rooms'
                filter
                optionLabel='label'
                options={roomsOptions.sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(e) => field.onChange(e.value)}
                onBlur={field.onBlur}
                value={field.value}
                optionDisabled={handleDisabledRoomsOptions}
                className={classNames({
                  'border-rust': !!fieldState.error,
                })}
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='primaryProviderSelected'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={false}
              label='Primary Provider'
              labelRef='primaryProviderSelected'
            >
              <Dropdown
                showClear
                inputId='primaryProviderSelected'
                placeholder='Select the primary provider'
                filter
                optionLabel='label'
                options={personnelProviders.sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(e: DropdownChangeEvent) => {
                  const provider = e.target.value;
                  field.onChange(provider);
                  setValue(
                    'additionalProvidersSelected',
                    additionalProvidersSelected?.filter((i) => i.id !== provider?.id)
                  );
                }}
                value={field.value}
                focusInputRef={field.ref}
                disabled={!appointment?.atriaAppointment}
              />
            </FormField>
          )}
        />
      </div>

      {primaryProviderSelected?.id && !appointment.athenaAppointmentId && (
        <div className='flex mt-4'>
          <Controller
            control={control}
            name='athenaDepartment'
            render={({ field, fieldState }) => (
              <FormField
                error={fieldState.error?.message}
                firstColumn={true}
                isRequired={false}
                label='Athena Department'
                labelRef='athenaDepartment'
              >
                <Dropdown
                  inputId='athenaDepartment'
                  placeholder='Select a department'
                  filter
                  optionLabel='name'
                  options={getAthenaDepartments()}
                  onChange={(e) => handleAthenaDepartmentChange(e.value)}
                  value={field.value}
                  focusInputRef={field.ref}
                  showClear={true}
                />
              </FormField>
            )}
          />
        </div>
      )}

      <div className='flex mt-4'>
        <Controller
          control={control}
          name='liaisonProviderSelected'
          render={({ field, fieldState }) => (
            <FormField
              error={fieldState.error?.message}
              firstColumn={true}
              isRequired={false}
              label='Liaison'
              labelRef='liaisonProviderSelected'
            >
              <Dropdown
                showClear
                inputId='liaisonProviderSelected'
                placeholder='Select the liaison'
                filter
                optionLabel='label'
                options={
                  primaryProviderSelected
                    ? personnelProviders
                        .filter((p) => p.id !== primaryProviderSelected?.id)
                        .sort((a, b) => a.label.localeCompare(b.label))
                    : personnelProviders.sort((a, b) => a.label.localeCompare(b.label))
                }
                onChange={(e: DropdownChangeEvent) => {
                  const provider = e.target.value;
                  field.onChange(provider);
                  setValue(
                    'additionalProvidersSelected',
                    additionalProvidersSelected?.filter((i) => i.id !== provider.id)
                  );
                }}
                value={field.value}
                focusInputRef={field.ref}
              />
            </FormField>
          )}
        />
      </div>

      <div className='flex mt-4'>
        <Accordion>
          <AccordionItem
            id='aditionalClinitianAndResources'
            title='Add additional clinician(s) & resources'
            formMode={true}
          >
            <div className='flex mt-4'>
              <Controller
                control={control}
                name='additionalProvidersSelected'
                render={({ field, fieldState }) => (
                  <FormField
                    error={fieldState.error?.message}
                    firstColumn={true}
                    isRequired={false}
                    label='Additional Clinician'
                    labelRef='additionalProvidersSelected'
                  >
                    <MultiSelect
                      inputId='additionalProvidersSelected'
                      placeholder='Select additional staff'
                      filter
                      optionLabel='label'
                      options={filteredProviders.sort((a, b) => a.label.localeCompare(b.label))}
                      onChange={(e) => field.onChange(e.value)}
                      onBlur={field.onBlur}
                      value={field.value}
                    />
                  </FormField>
                )}
              />
            </div>
            <div className='flex mt-4'>
              <Controller
                control={control}
                name='resourcesSelected'
                render={({ field, fieldState }) => (
                  <FormField
                    error={fieldState.error?.message}
                    firstColumn={true}
                    isRequired={false}
                    label='Resources'
                    labelRef='resourcesSelected'
                  >
                    <MultiSelect
                      inputId='resourcesSelected'
                      placeholder='Select resources'
                      filter
                      optionLabel='label'
                      options={nonPersonnelProviders.sort((a, b) => a.label.localeCompare(b.label))}
                      onChange={(e) => field.onChange(e.value)}
                      onBlur={field.onBlur}
                      value={field.value}
                    />
                  </FormField>
                )}
              />
            </div>
          </AccordionItem>
        </Accordion>
      </div>

      {appointment?.atriaAppointment && (
        <div className='flex mt-4'>
          <Controller
            control={control}
            name='appointmentConfirmed'
            render={({ field, fieldState }) => (
              <FormField
                error={fieldState.error?.message}
                firstColumn={true}
                isRequired={false}
                label='Status:'
                labelRef='appointmentConfirmed'
              >
                <ConfirmedStatus
                  value={field.value ?? ConfirmedTypes.HOLD_CONFIRMED}
                  onChange={(e) => field.onChange(e.value)}
                  canClickOnHold={!appointment.athenaAppointmentId}
                  optionLabel='name'
                  options={confirmedTypesOptions}
                />
              </FormField>
            )}
          />
        </div>
      )}

      {env.APP_FEATURE_FLAGS.IS_TO_ENABLE_SYNC_FLAGS && (
        <div className='flex mt-4 gap-6'>
          {appointment.athenaAppointmentId ? (
            <label className='text-xs font-bold mt-1'>Added to Athena</label>
          ) : (
            <Controller
              control={control}
              name='syncToAthena'
              render={({ field, fieldState }) => (
                <FormField
                  disabled={!canSyncToAthena}
                  error={fieldState.error?.message}
                  firstColumn={true}
                  inline={true}
                  isRequired={false}
                  label='Add to Athena'
                  labelRef='syncAthena'
                  reverted={true}
                >
                  <InputSwitch
                    inputId='syncAthena'
                    checked={field.value ?? false}
                    onChange={(e) => field.onChange(e.value)}
                    disabled={!canSyncToAthena}
                  />
                </FormField>
              )}
            />
          )}

          {env.APP_FEATURE_FLAGS.IS_TO_ENABLE_GOOGLE_SYNC_FLAG && (
            <>
              {appointment.googleCalendarEventId ? (
                <label className='text-xs font-bold mt-1'>Added to Google Calendar</label>
              ) : (
                <Controller
                  control={control}
                  name='syncToGoogle'
                  render={({ field, fieldState }) => (
                    <FormField
                      error={fieldState.error?.message}
                      firstColumn={true}
                      inline={true}
                      isRequired={false}
                      label='Add to Google Calendar'
                      labelRef='syncGoogle'
                      reverted={true}
                      disabled={
                        !!appointment.googleCalendarEventId ||
                        (!primaryProviderSelected && !additionalProvidersSelected?.length)
                      }
                    >
                      <InputSwitch
                        inputId='syncGoogle'
                        checked={field.value ?? false}
                        onChange={(e) => field.onChange(e.value)}
                        disabled={
                          !!appointment.googleCalendarEventId ||
                          (!primaryProviderSelected && !additionalProvidersSelected?.length)
                        }
                      />
                    </FormField>
                  )}
                />
              )}
            </>
          )}
        </div>
      )}

      <p className='text-[#757575] text-[11px] font-medium mt-[11px] ml-1'>
        {SyncHelper.messageSyncGoogleAndAthena}
      </p>

      <div className='w-full flex flex-col gap-2 mt-4'>
        <Button
          label='Save'
          size='small'
          className='w-full'
          type='submit'
          disabled={Object.values(formState.errors).length > 0}
        />
        <Button
          label='Cancel'
          size='small'
          className='w-full'
          type='reset'
          onClick={handleCancelFormButton}
          outlined
        />
      </div>
    </form>
  );
};
