import { safeJSONParse } from '@/utils';
import { env } from '@/utils/constants';
import React, { useEffect, useRef } from 'react';
import useReactWebSocket from 'react-use-websocket';
import { WebSocketContext } from './webSocketContext';

interface MessagePayload {
  type: string;
  data: any;
}

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const subscribers = useRef<Map<string, Set<Function>>>(new Map());
  const { lastJsonMessage } = useReactWebSocket<MessagePayload>(env.APP_SOCKET_URL, {
    share: true,
    filter: (message) => {
      const msg = safeJSONParse(message.data);
      return (
        msg &&
        subscribers.current.has(msg.type) &&
        (subscribers.current.get(msg.type)?.size || 0) > 0
      );
    },
    shouldReconnect: () => true,
    reconnectAttempts: 20,
    reconnectInterval: (times) => times * 500,
    heartbeat: true,
  });

  useEffect(() => {
    if (lastJsonMessage) {
      const handlers = subscribers.current.get(lastJsonMessage.type);
      if (handlers) {
        handlers.forEach((handler) => handler(lastJsonMessage));
      }
    }
  }, [lastJsonMessage, subscribers]);

  const subscribe = (type: string, callback: (data: any) => void) => {
    if (!subscribers.current.has(type)) {
      subscribers.current.set(type, new Set());
    }
    subscribers.current.get(type)?.add(callback);
  };

  const unsubscribe = (type: string, callback: (data: any) => void) => {
    subscribers.current.get(type)?.delete(callback);
  };

  return (
    <WebSocketContext.Provider value={{ subscribe, unsubscribe }}>
      {children}
    </WebSocketContext.Provider>
  );
};
