enum appointmentTypeEnum {
  PhoneCall = 421,
}

type Patient =
  | { firstName: string; firstNameUsed?: string | null; lastName: string }
  | null
  | undefined;
type PrimaryProvider = { label: string; id: number } | null | undefined;
type AppointmentType = { id: number; name: string } | null | undefined;

/**
 * Return custom title used for Google Calendar
 * @param appointment
 * @returns
 */
export function getAppointmentGoogleTitle(
  title: string,
  primaryProvider: PrimaryProvider,
  appointmentType: AppointmentType,
  patient: Patient
) {
  return `${getAppointmentSuffix(appointmentType)}: ${getPatientName(patient)}, ${title} (${primaryProvider?.label})`;
}

function getPatientName(patient: Patient) {
  if (!patient) {
    return;
  }

  if (patient.firstNameUsed) {
    return `${patient.firstNameUsed[0]}. ${patient.lastName}`;
  }

  return `${patient.firstName[0]}. ${patient.lastName}`;
}

function getAppointmentSuffix(appointmentType: AppointmentType) {
  if (appointmentType) {
    if (appointmentType.name.toLowerCase().includes('tele')) {
      return 'Atria Virtual Visit';
    }

    if (appointmentType.name.toLocaleLowerCase().includes('home')) {
      return 'Atria Home Visit';
    }

    if (appointmentType.id === appointmentTypeEnum.PhoneCall) {
      return 'Atria Phone Call';
    }
  }

  return 'Atria Institute Visit';
}
