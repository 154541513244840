import { useWebSocket } from '@/contexts/webSocket/webSocketContext';
import { useCallback, useEffect, useState } from 'react';

type Message = {
  type: string;
  payload: {
    status: number;
  };
};

export const useAthenaStatusSocket = () => {
  const { subscribe, unsubscribe } = useWebSocket();
  const [isOnline, setIsOnline] = useState(true);

  const handleWsMessage = useCallback(
    (data: Message) => {
      if (data) {
        const athenaStatus = data.payload;
        setIsOnline(athenaStatus.status === 200);
      }
    },
    [setIsOnline]
  );

  useEffect(() => {
    subscribe('ATHENA_STATUS_CHANGED', handleWsMessage);

    return () => {
      unsubscribe!('ATHENA_STATUS_CHANGED', handleWsMessage);
    };
  }, [subscribe, unsubscribe, handleWsMessage]);

  return {
    isOnline,
  };
};
